import * as _stream2 from "stream";

var _stream = "default" in _stream2 ? _stream2.default : _stream2;

import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;

/*
CSV Generate - main module

Please look at the [project documentation](https://csv.js.org/generate/) for
additional information.
*/
const stream = _stream;
const util = _util;

exports = function () {
  let options;
  let callback;

  if (arguments.length === 2) {
    options = arguments[0];
    callback = arguments[1];
  } else if (arguments.length === 1) {
    if (typeof arguments[0] === "function") {
      options = {};
      callback = arguments[0];
    } else {
      options = arguments[0];
    }
  } else if (arguments.length === 0) {
    options = {};
  }

  const generator = new Generator(options);

  if (callback) {
    const data = [];
    generator.on("readable", function () {
      let d;

      while (d = generator.read()) {
        data.push(d);
      }
    });
    generator.on("error", callback);
    generator.on("end", function () {
      if (generator.options.objectMode) {
        callback(null, data);
      } else {
        if (generator.options.encoding) {
          callback(null, data.join(""));
        } else {
          callback(null, Buffer.concat(data));
        }
      }
    });
  }

  return generator;
};

_global.Generator = function (options = {}) {
  // Convert Stream Readable options if underscored
  if (options.high_water_mark) {
    options.highWaterMark = options.high_water_mark;
  }

  if (options.object_mode) {
    options.objectMode = options.object_mode;
  } // Call parent constructor


  stream.Readable.call(this || _global, options); // Clone and camelize options

  (this || _global).options = {};

  for (let k in options) {
    (this || _global).options[Generator.camelize(k)] = options[k];
  } // Normalize options


  const dft = {
    columns: 8,
    delimiter: ",",
    duration: null,
    encoding: null,
    end: null,
    eof: false,
    fixedSize: false,
    length: -1,
    maxWordLength: 16,
    rowDelimiter: "\n",
    seed: false,
    sleep: 0
  };

  for (const k in dft) {
    if ((this || _global).options[k] === undefined) {
      (this || _global).options[k] = dft[k];
    }
  } // Default values


  if ((this || _global).options.eof === true) {
    (this || _global).options.eof = (this || _global).options.rowDelimiter;
  } // State


  (this || _global)._ = {
    start_time: (this || _global).options.duration ? Date.now() : null,
    fixed_size_buffer: "",
    count_written: 0,
    count_created: 0
  };

  if (typeof (this || _global).options.columns === "number") {
    (this || _global).options.columns = new Array((this || _global).options.columns);
  }

  const accepted_header_types = Object.keys(Generator).filter(t => !["super_", "camelize"].includes(t));

  for (let i = 0; i < (this || _global).options.columns.length; i++) {
    const v = (this || _global).options.columns[i] || "ascii";

    if (typeof v === "string") {
      if (!accepted_header_types.includes(v)) {
        throw Error(`Invalid column type: got "${v}", default values are ${JSON.stringify(accepted_header_types)}`);
      }

      (this || _global).options.columns[i] = Generator[v];
    }
  }

  return this || _global;
};

util.inherits(Generator, stream.Readable); // Export the class

exports.Generator = Generator; // Generate a random number between 0 and 1 with 2 decimals. The function is idempotent if it detect the "seed" option.

Generator.prototype.random = function () {
  if ((this || _global).options.seed) {
    return (this || _global).options.seed = (this || _global).options.seed * Math.PI * 100 % 100 / 100;
  } else {
    return Math.random();
  }
}; // Stop the generation.


Generator.prototype.end = function () {
  this.push(null);
}; // Put new data into the read queue.


Generator.prototype._read = function (size) {
  // Already started
  const data = [];
  let length = (this || _global)._.fixed_size_buffer.length;

  if (length !== 0) {
    data.push((this || _global)._.fixed_size_buffer);
  }

  while (true) {
    // Time for some rest: flush first and stop later
    if ((this || _global)._.count_created === (this || _global).options.length || (this || _global).options.end && Date.now() > (this || _global).options.end || (this || _global).options.duration && Date.now() > (this || _global)._.start_time + (this || _global).options.duration) {
      // Flush
      if (data.length) {
        if ((this || _global).options.objectMode) {
          for (const line of data) {
            this.__push(line);
          }
        } else {
          this.__push(data.join("") + ((this || _global).options.eof ? (this || _global).options.eof : ""));
        }
      } // Stop


      return this.push(null);
    } // Create the line


    let line = [];
    let lineLength;

    (this || _global).options.columns.forEach(fn => {
      line.push(fn(this || _global));
    }); // for(const header in this.options.columns){
    //   // Create the field
    //   line.push(header(this))
    // }
    // Obtain line length


    if ((this || _global).options.objectMode) {
      lineLength = 0;

      for (const column of line) lineLength += column.length;
    } else {
      // Stringify the line
      line = ((this || _global)._.count_created === 0 ? "" : (this || _global).options.rowDelimiter) + line.join((this || _global).options.delimiter);
      lineLength = line.length;
    }

    (this || _global)._.count_created++;

    if (length + lineLength > size) {
      if ((this || _global).options.objectMode) {
        data.push(line);

        for (const line of data) {
          this.__push(line);
        }
      } else {
        if ((this || _global).options.fixedSize) {
          (this || _global)._.fixed_size_buffer = line.substr(size - length);
          data.push(line.substr(0, size - length));
        } else {
          data.push(line);
        }

        this.__push(data.join(""));
      }

      return;
    }

    length += lineLength;
    data.push(line);
  }
}; // Put new data into the read queue.


Generator.prototype.__push = function (record) {
  (this || _global)._.count_written++;

  if ((this || _global).options.sleep > 0) {
    setTimeout(() => {
      this.push(record);
    }, (this || _global).options.sleep);
  } else {
    this.push(record);
  }
}; // Generate an ASCII value.


Generator.ascii = function (gen) {
  // Column
  const column = [];
  const nb_chars = Math.ceil(gen.random() * gen.options.maxWordLength);

  for (let i = 0; i < nb_chars; i++) {
    const char = Math.floor(gen.random() * 32);
    column.push(String.fromCharCode(char + (char < 16 ? 65 : 97 - 16)));
  }

  return column.join("");
}; // Generate an integer value.


Generator.int = function (gen) {
  return Math.floor(gen.random() * Math.pow(2, 52));
}; // Generate an boolean value.


Generator.bool = function (gen) {
  return Math.floor(gen.random() * 2);
}; // Camelize option properties


Generator.camelize = function (str) {
  return str.replace(/_([a-z])/gi, function (_, match, index) {
    return match.toUpperCase();
  });
};

export default exports;
const _Generator = exports.Generator;
export { _Generator as Generator };